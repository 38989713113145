function loadSliders() {
	$('.testimonials .carousel-inner').slick({
		centerMode: false,
		centerPadding: '0',
		slidesToShow: 3,
		arrows:false,
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});
}
var sliderScript = document.getElementById("deferredSlickScript");
if (sliderScript) {
	sliderScript.addEventListener('load', function () {
		loadSliders();
	});
}
$(document).ready(function () {
    $("img.has-tooltip").tooltip({
        title: function () {
            return $(this).attr("alt");
        },
        placement: "auto"
    });
    $("a.has-tooltip").tooltip({
        placement: "auto"
	});
	var testimonialAuthors = $(".RotatorTestimonialContent a[itemprop='author']");
	if (testimonialAuthors.length > 0) {
		$(".RotatorTestimonialContent a[itemprop='author']").each(function () {
			$(this)[0].nextSibling.nodeValue = '';
		});
	}
	function fixHeights() {
		$(".RotatorTestimonial").sameHeight();
		$(".featured-categories__title").sameHeight();
		$(".featured-categories__description").sameHeight();
	}
	setTimeout(fixHeights, 500);
	$(window).on("resize", function () {
		setTimeout(fixHeights, 500);
	});
});